import * as React from 'react';

import { Image } from '../components/workimages/workimages';
import Seo from '../components/seo';
import './work.style.scss';

const Work = ({ pageContext }) => {
  const work = pageContext;
  const serviceItems = work.services.map((service, key) => (
    <li key={key}>{service}</li>
  ));
  const techItems = work.technologies.map((tech, key) => (
    <li key={key}>{tech}</li>
  ));
  return (
    <main id='main' className='page-single--works'>
      <Seo title={work.seotitle} />
      <section className='section work'>
        <section>
          <h1 className='page-title__label'>
            {work.title}
            <span className='dot'>.</span>
          </h1>
          <h2>{work.heading}</h2>
        </section>
        <section className='page__content'>
          <p>{work.content}</p>
          {work.viewwebsite === true && (
            <a
              title={work.websitetitle}
              href={work.websiteurl}
              className='btn cta__btn transform-scale-h'
              rel='noopener noreferrer'
              target='_blank'
            >
              Visit Site
            </a>
          )}
          <figure>
            <Image src={work.src} />
          </figure>
        </section>
      </section>
      <section className='content--services'>
        <div>
          <h2>Services</h2>
          <ul>{serviceItems}</ul>
        </div>
        <div>
          <h2>Technologies</h2>
          <ul>{techItems}</ul>
        </div>
      </section>
    </main>
  );
};

export default Work;
