import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const Image = ({ src }) => {
  return (
    <>
      {src === 1 ? (
        <StaticImage
          quality={95}
          layout='fullWidth'
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--fritz.png'
          alt='Michael Fritz Letterhead Sample Card'
        />
      ) : src === 2 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--90state.png'
          alt='Mockups of 90stateevents.com on mobile and desktop'
        />
      ) : src === 3 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--glensandersmansion.png'
          alt='Mockups of glensandersmansion.com on mobile and desktop'
        />
      ) : src === 4 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--hallofsprings.png'
          alt='Mockups of hallofsprings.com on mobile and desktop'
        />
      ) : src === 5 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--mazzonecatering.png'
          alt='Mockups of mazzonecatering.com on mobile and desktop'
        />
      ) : src === 6 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--loft433.png'
          alt='Mockups of glensandersmansion.com on mobile and desktop'
        />
      ) : src === 7 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--mazzonehospitality.png'
          alt='Mockups of hallofsprings.com on mobile and desktop'
        />
      ) : src === 8 ? (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--metrolandphoto.png'
          alt='Mockups of hallofsprings.com on mobile and desktop'
        />
      ) : (
        <StaticImage
          layout='fullWidth'
          quality={95}
          placeholder='blurred'
          formats={['auto', 'webp', 'avif']}
          src='../../images/works/details--loft433.png'
          alt='Michael Fritz Letterhead Sample Card'
        />
      )}
    </>
  );
};
